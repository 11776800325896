import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Form, Spinner, Table } from "react-bootstrap";

import type { APIKey, Page } from "../api/types";
import { fetchAPIKeys, postAPI } from "../api";

const apikeyQueries = {
  all: () => ["api_keys"],
};

type NewAPIKey = {
  notes: string;
};

function NewAPIKeyRow() {
  const [notes, setNotes] = useState<string>("");

  const queryClient = useQueryClient();
  const createAPIKey = useMutation({
    mutationFn: (newAPIKey: NewAPIKey) => postAPI(`/api/api-keys`, newAPIKey),
    onSuccess: (apiKey) => {
      setNotes("");

      queryClient.setQueryData<Page<APIKey>>(
        apikeyQueries.all(),
        (apiKeys) =>
          apiKeys && {
            ...apiKeys!,
            items: [...apiKeys!.items, apiKey],
          },
      );
    },
  });

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="notes"
        />
      </td>

      <td>
        <Button
          variant="light"
          onClick={() => createAPIKey.mutate({ notes })}
          disabled={createAPIKey.isPending || !notes}
        >
          {createAPIKey.isPending ? <Spinner size="sm" /> : "➕"}
        </Button>
      </td>
    </tr>
  );
}

export type RevealedSecretKey = {
  id: string;
  secret_key: string;
};

function RevealableAPIKey({ apiKey }: { apiKey: APIKey }) {
  const [revealedSecretKey, setRevealedSecretKey] = useState<string | null>(
    apiKey.secret_key || null,
  );
  const [submitting, setSubmitting] = useState(false);

  const revealSecretKey = (apiKey: APIKey) => {
    setSubmitting(true);
    postAPI(`/api/api-keys/${apiKey.id}/reveal`, {})
      .then((k: RevealedSecretKey) => {
        setSubmitting(false);
        setRevealedSecretKey(k.secret_key);
      })
      .catch((err) => console.log(err));
  };

  if (revealedSecretKey) return <code>{revealedSecretKey}</code>;

  return (
    <>
      <code>{apiKey.redacted_secret_key}</code>{" "}
      <span className="float-end">
        {submitting ? (
          <Spinner size="sm" />
        ) : (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => revealSecretKey(apiKey)}
            className="bi-eye-fill"
          />
        )}
      </span>
    </>
  );
}

function APIKeysPage() {
  const {
    isPending,
    isError,
    data: apiKeys,
    error,
  } = useQuery({ queryKey: ["api_keys"], queryFn: fetchAPIKeys });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">Could not load API keys: {error.message}.</Alert>
    );
  } else {
    return (
      <>
        <h2>API keys</h2>

        <p>
          API keys are credentials that allow your client software to access the
          Artificial.Agency API.
        </p>

        <Table>
          <thead>
            <tr>
              <th>API Key</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.items.map((k) => {
              return (
                <tr key={k.id}>
                  <td>
                    <RevealableAPIKey apiKey={k} />
                  </td>
                  <td>{k.notes}</td>
                </tr>
              );
            })}
            <NewAPIKeyRow />
          </tbody>
        </Table>
      </>
    );
  }
}

export default APIKeysPage;
